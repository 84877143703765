import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ExploreFeature from "./ExploreFeature";
import './assets/styles/_index.scss';
import GetGatsbyImage from '../common/site/get-gatsby-image';

const BeInspiredSlider = () => {


    const data = useStaticQuery(graphql`
    query GetInspiredData {
      glstrapi {
        globalModule {
            Be_Inspired {
              Area_Listings {
                Name
                Content
                Link
                Image {
                  alternativeText
                  url
                  url_sharp {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        height: 328
                        width: 264
                        layout: FIXED
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
                }
              }
              Amenities_Listings {
                Icon
                Link
                Name
              }
            }
        }
      }
    }
  `)



    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 400,
                infinite: false,
                arrows: false,
                dots: true,
                centerMode: true,
                centerPadding: '20%',
                },
            },
        ],
    }
    // Slider settings
    const arealist = data.glstrapi.globalModule.Be_Inspired.Area_Listings;
    const amenitieslist = data.glstrapi.globalModule.Be_Inspired.Amenities_Listings;

    const scrollTop = () => {
        if (typeof window != 'undefined') {
            window.scrollTo(0,0)
        }
    }
    return (
        <section className="be-inspired-wrapper section-m">
            <div className="be-inspired-slider-wrapper">
                <Container>
                    <Row>
                        <Col>
                            <h4>Be Inspired</h4>
                            <div className="d-flex justify-content-between align-items-center be-inspired-header-wrapper">
                                <h2>Our Properties by area</h2>
                                {/* <Link to="/about/dubai-communities/" className="view-more d-md-block d-none">View More</Link> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Slider className="be-inspired-slider" {...settings}>
                            {arealist && arealist.length > 0 && arealist.map((area, index) => {
                                return<>
                                <div className="be-inspired-slide">
                                    <div className="be-inspired-img-zoom">
                                        <Link to={area.Link} onClick={() => scrollTop()}>
                                            <GetGatsbyImage
                                                image={area?.Image[0]}
                                                fallbackalt={area.Name}
                                                className="img-fluid"
                                            />
                                        </Link>
                                    </div>
                                    <div className="be-inspired-text-wrapper">
                                        <Link to={area.Link} onClick={() => scrollTop()} className="d-flex justify-content-between align-items-center">
                                            <div className="link-text">{area.Name}</div>
                                            {/* <i className="icon icon-inspired-slider-link-arrow"></i> */}
                                        </Link>
                                        <div className="inspired-text">{area.Content}</div>
                                    </div>
                                </div>
                                </>
                            })}                               
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>

            <ExploreFeature amenitieslist={amenitieslist}/>
        </section>
    )
}

export default BeInspiredSlider