import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { graphql, Link, useStaticQuery } from "gatsby"
import PlayVideo from "../PlayVideo/PlayVideo";
import './assets/styles/_index.scss';
import Img1 from "../../images/testimonial-img.png";
import GetGatsbyImage from '../common/site/get-gatsby-image';

const TestimonialSlider = (props) => {

    const [isPlay, setPlay] = useState(false);
    const [videourl, setVideourl] = useState(false);
    const [videotour, setVideoTour] = useState(false);

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 400,
                infinite: false,
                arrows: false,
                dots: true,
                centerMode: true,
                centerPadding: '20%',
                },
            },
        ],
    }
    // Slider settings

    const data = useStaticQuery(graphql`
    query GetClientVideos {
      glstrapi {
        videos(where: {
            select_video_category: {
              Name: "testimonials"
            }
          }) {
              Title
              Content
              Video_Image {
                alternativeText
                url
                url_sharp {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        width: 362
                        height: 350                        
                        layout: FIXED
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
              }
              Video_URL
              select_video_category {
                Name
              }
            }        
      }
    }
  `)
    const videos = data.glstrapi.videos;
    return (
        <section className={`testimonial-slider-wrapper section-p ${
            props.theme === "Dark" ? "dark-bg" : ""
          }`}>
            <Container>
                <Row>
                    <Col>
                        <h4>{props.title ? props.title : "TESTIMONIALS"}</h4>
                        <div className="d-flex justify-content-between align-items-center testimonial-header-wrapper">
                            <h2>{props.description ? props.description : "Reviews from our clients"}</h2>
                            <div className="d-md-flex d-none align-items-center google-reviews">
                                <i className="icon icon-google-reviews"></i>
                                <div className="review-text d-flex align-items-center">4.95/5 <span className="d-lg-flex d-none">from 148 Reviews</span></div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Slider className="testimonial-slider" {...settings}>
                        {videos.map(item => (
                            <div className="testimonial-slide">
                                <div className="testimonial-play-wrapper">
                                    {item.Video_URL &&
                                        <a onClick={(e) => { setPlay(true); setVideourl(item?.Video_URL); setVideoTour(false);}}>
                                            <div className="testimonial-play d-flex align-items-center justify-content-center"><i className="icon icon-play"></i></div>
                                        </a>
                                    }
                                </div>
                                <div className="testimonial-img-zoom">
                                    <Link to={"#"}>
                                        {/* <img src={Img1} className="img-fluid" /> */}
                                        <GetGatsbyImage
                                                image={item?.Video_Image}
                                                fallbackalt={item.Title}
                                                className="img-fluid"
                                            />
                                    </Link>
                                </div>
                                <div className="testimonial-text-wrapper">
                                    <Link to={"#"} className="link-text">{item.Title}</Link>
                                    <div className="testimonial-text">{item.Content}</div>
                                </div>
                            </div>
                        ))}
                            {/* <div className="testimonial-slide">
                                <div className="testimonial-play-wrapper">
                                    <a onClick={(e) => { setPlay(true); setVideourl("https://youtu.be/2Gg6Seob5Mg"); setVideoTour(false);}}>
                                        <div className="testimonial-play d-flex align-items-center justify-content-center"><i className="icon icon-play"></i></div>
                                    </a>
                                </div>
                                <div className="testimonial-img-zoom">
                                    <Link to={"#"}>
                                        <img src={Img1} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="testimonial-text-wrapper">
                                    <Link to={"#"} className="link-text">Miss Hubbard</Link>
                                    <div className="testimonial-text">Property Management</div>
                                </div>
                            </div>
                            <div className="testimonial-slide">
                                <div className="testimonial-play-wrapper">
                                    <a onClick={(e) => { setPlay(true); setVideourl("https://youtu.be/2Gg6Seob5Mg"); setVideoTour(false);}}>
                                        <div className="testimonial-play d-flex align-items-center justify-content-center"><i className="icon icon-play"></i></div>
                                    </a>
                                </div>
                                <div className="testimonial-img-zoom">
                                    <Link to={"#"}>
                                        <img src={Img1} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="testimonial-text-wrapper">
                                    <Link to={"#"} className="link-text">Mr Jamie Smith</Link>
                                    <div className="testimonial-text">Buyer</div>
                                </div>
                            </div> */}
                        </Slider>

                        <div className="d-xl-none d-lg-none d-md-none d-flex align-items-center google-reviews reviews-bottom">
                            <i className="icon icon-google-reviews"></i>
                            <div className="review-text d-flex align-items-center">4.95/5 from 148 Reviews</div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isPlay && videourl &&
                <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={videourl} htmlink={""}/>
            }
        </section>
    )
}

export default TestimonialSlider