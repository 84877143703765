import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import "./assets/styles/_index.scss"
import Img1 from "../../images/people-img-1.png"
import Img2 from "../../images/people-img-2.png"
import Img3 from "../../images/people-img-3.png"
import Img4 from "../../images/people-img-4.png"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { TeamsURL } from "../commonURLs"

const OurPeopleSlider = () => {
  const data = useStaticQuery(graphql`
    query GetArticle {
      glstrapi {
        teams(limit: 10) {
          id
          name
          slug
          publish
          designation
          email
          tile_image {
            url
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 362
                  height: 350
                  layout: FIXED
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `)

  const teams = data.glstrapi.teams

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "20%",
        },
      },
    ],
  }
  // Slider settings

  return (
    <section className="our-people-slider-wrapper section-p">
      <Container>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center our-people-header-wrapper">
              <h2>Our People</h2>
              <Link to={TeamsURL} className="view-more">
                View More
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Slider className="our-people-slider" {...settings}>
              {teams?.length > 0 &&
                teams.map(team => (
                  <div className="our-people-slide">
                    <div className="our-people-img-zoom">
                      <Link to={TeamsURL + team.slug + "/"}>
                        {/* <img src={team.tile_image?.url} className="img-fluid" /> */}
                        <GetGatsbyImage
                          image={team.tile_image}
                          fallbackalt={team.name}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="our-people-text-wrapper">
                      <Link
                        to={TeamsURL + team.slug + "/"}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div className="link-text">{team.name}</div>
                        <i className="icon icon-inspired-slider-link-arrow"></i>
                      </Link>
                      <div className="our-people-text">{team.designation}</div>
                    </div>
                  </div>
                ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OurPeopleSlider
