import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api" 
import $ from 'jquery/dist/jquery.min.js'
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router"
import { parse } from "query-string"

// import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"
import _ from "lodash";

function CareerForm(props) {

  const location = useLocation();    
  const searchParams = parse(location.search) // => {init: "true"}
  var jobname = searchParams.jobtitle
  const jobdata = useStaticQuery(graphql`
  query  {  
    glstrapi {
      careers {
        title
      }
    }
  }
  `)
  var jobdatalist = jobdata.glstrapi.careers;

  const [joblist, setJobList] = useState([]);
  useEffect(() => {
  if(jobdatalist && jobdatalist.length > 0) {    
    setJobList(jobdatalist.map((item, i) => 
      item.title
    ))    
  }
  },[])
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: jobname?"Apply Job":"Send us your CV",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. We will be in touch with you shortly.",
      email_temp_user: "careers_user",
      email_temp_admin: "careers_admin",
      email_subject_user:"Your Job Application",
      email_subject_admin:"New Job Application",
      email_server_func: "careers",
      event_tracking: "careers",
      page_url: "/careers/job-listing"
    },
    {
      grpmd: "12",
      label: "Full Name",
      placeholder: "",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
    },

    {
      grpmd: "12",
      label: "Phone",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      fieldClass: "",
      labelClass: "content_b-18",
      step:"two-side-input",
      filedwrap:"left-field",
    },
    {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: "",
        step:"two-side-input",
        filedwrap:"right-field",
      },
      {
        grpmd: "12",
        label: "Job",
        name: "jobname",
        id: "jobname",
        element: "select",
        required: true,
        type: "select",
        class: "select-box",
        values: joblist,
      },
    {
      grpmd: "col-md-12",
      label: "Attach CV:",
      placeholder: "browse",
      name: "file",
      type: "file",
      element: "file",
      required: true,
      accept: ".pdf,.doc",
      fieldClass: "form-control d-flex flex-column justify-content-center form-control-browsefile custom custom-file",
      labelClass: "content_b-18"
    },
    {
      type: "hidden",
      element: "input",
      name: "cvname"
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder:"Write your message here...",
      name: "message",
      required: false,
      element: "textarea",
      class: "form__field form__field-textarea",
      rows: "3",
      labelClass: "content_b-18"
    },

    {
      name: "Submit details",
      type: "submit",
      element: "button",
      value: "submit",
      step: "",
      formclass: ""
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const handleselectchange = event => {
    // remove initial empty spaces
    event.value = event.value.trimStart()
  }
  
  const handleAttachment = event => {
    const fileItem = event.target.files[0]; // accesing file
    var d = document.getElementById('custom-file');    
    $('.upload .form-control-browsefile span').html(d.value.replace(/C:\\fakepath\\/, ''));
    if(d.value === "") {
      $('.upload .form-control-browsefile span').html("Browse");
    }
    if ((fileItem == '') || (fileItem == null) || (fileItem == undefined)) {
      $('.form-control-browsefile').addClass('fileerror');
      $('.form-control-browsefile').removeClass('fileadded');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
      $('.form-control-browsefile').addClass('fileadded');
    }

    setFile(fileItem); // storing file 
  };


  useEffect(() => {
 
    if (token !== '') {

      const processFromData = async () => {


        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }
        formvalues['extra'] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name
        }); 

        formvalues['name'] = formvalues.name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {

          if (apiRes?.attachment?.url) {
            // If image from externall url, use that
            if (apiRes.attachment.url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment.url}`;
              formvalues['cvname'] = `${apiRes.attachment.url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
            }
          }
          window.grecaptcha.reset()
         
           // lets send mail
           
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
      
        });

        const url = typeof window !== 'undefined' ? window.location.href : ''
        
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });
        setFile("");
        $('.form-control-browsefile').removeClass('fileadded');
        setShowerror(false);
        setThankyou(true);
        // setTimeout(() => {
        //   $(".alert-success").show().delay(4000).fadeOut();
        // }, 500)
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // $('html, body').animate({
        //   scrollTop: $(".career-form").offset().top
        // }, 300);

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    if ((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      $(".input-box.upload input").each(function(){
        if ($(this).is(":invalid")) {
          $(this).closest(".form-group").addClass("invalid--field");
        } else {
          $(this).closest(".form-group").removeClass("invalid--field");
        }      
      })
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

    //   $('html, body').animate({
    //     scrollTop: $(".career-form").offset().top
    //   }, 300);
    }
    else {
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])
      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };

  return (
    <div className="form stbform common_form_class" id="contactform">
    <div ref={myRef} />
    <div className="form-wraps input-wraps">
    {showerror && <div className="alert alert-danger alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert-success">
        <p>{fields[0].success_text}</p>
      </div>}
      <Form className="form contact-form common_form_class enquiry-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} netlify-honeypot="bot-field" data-netlify-recaptcha="true">

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.toemail} />
        <input type="hidden" name="bot-field" />
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  filedwrap={field.filedwrap}
                  value={field.value}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  step={field.step}
                />
              );
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                  file={file}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  id={field.id}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handleselectchange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  label={field.label}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  step={field.step}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  step={field.step}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
          }
      </Form>

    </div>
    </div>
  );
}


const CareerFormPage = (props) => (
  <CareerForm toemail={props.toemail} jobname={props.jobname} type={props.type} classone={props.classone} title={props.title} />
)

export default CareerFormPage

