import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import parse from "html-react-parser"
import "./TileBlock.scss"
import { GetPageURL } from "../common/site/utils"
import { useEffect } from "react"
import { useState } from "react"
import { isMobileOnly } from "react-device-detect"

const TileBlockModule = ({ module }) => {
  const descriptionDivID = "tile-description-" + module?.id

  const showReadMore = module.text_block?.description.length > 400

  const [readMore, setReadMore] = useState(showReadMore)

  useEffect(() => {
    const element = document.getElementById(descriptionDivID)
    if (readMore) {
      if (isMobileOnly) {
        element.style.maxHeight = "307px"
      } else {
        element.style.maxHeight = "323px"
      }
    } else {
      element.style.maxHeight = ""
    }
  }, [readMore])

  return (
    <div
      className={`tile-block-module-wrapper  ${
        module.layout_type === "right_small_width_dark"
          ? "dark-bg section-p"
          : "section-m"
      }`}
    >
      <Container
        className={`tile-block-container ${
          module.layout_type === "left_small_width" ? "" : "img-right"
        }`}
      >
        <div className="img-block rounded">
          <GetGatsbyImage
            image={module.media_block.media_file}
            fallbackalt={module.text_block?.title}
          />
          {module.image_tag && <div className="tag">{module.image_tag}</div>}
        </div>
        <div className="content">
          <h4 className="title">{module.text_block?.title}</h4>
          {module.text_block?.description && (
            <div
              className={`description ${
                showReadMore ? "description-read-more" : ""
              } ${readMore ? "read-more" : ""}`}
              id={descriptionDivID}
            >
              {parse(module.text_block?.description)}
            </div>
          )}
          {showReadMore && (
            <button
              onClick={() => setReadMore(val => !val)}
              className={`read-more-button ${readMore ? "" : "read-less"}`}
            >
              {readMore ? "read more" : "read less"}
            </button>
          )}
          {module.Button_CTA && (
            <Link
              target={module.Button_CTA.target === "new_window" ? "_blank" : ""}
              to={GetPageURL(module.Button_CTA?.menu_item)}
              className="btn btn-grey"
            >
              {module.Button_CTA.label}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default TileBlockModule
