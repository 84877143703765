import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import BannerSearch from "./BannerSearch"
import "./assets/styles/_index.scss"
import BannerOverlay from "./assets/images/parallax-hero-bg.svg"
import BannerOverlayTablet from "./assets/images/parallax-hero-bg-tablet.svg"
import BannerImgMobile from "./assets/images/home-banner.jpg"
import bgImg from "./assets/images/home-banner.jpg"
import $ from "jquery"
import { useEffect } from "react"
import { isIOS } from "react-device-detect"
import { useMedia } from "react-use"

const HomeBannerSearch = () => {
  useEffect(() => {
    // backgroundAttachment: fixed - workaround for IOS
    if (isIOS) {
      $(window).scroll(function () {
        var scrolledY = $(window).scrollTop()
        $("#homewrapper").css("background-position", "left " + scrolledY + "px")
      })
    }
  })

  const isTablet = useMedia("(max-width: 992px)", false)

  return (
    <section className="home-banner-search-wrapper" id={"homewrapper"}>
      <img
        src={isTablet ? BannerOverlayTablet : BannerOverlay}
        className="img-fluid d-md-block d-none banner-overlay-img"
      />
      {/* <img src={bgImg} className="img-fluid banner-img" /> */}
      <img
        src={BannerImgMobile}
        className="d-md-none d-block banner-img-mobile"
      />
      <div className="overlay-bg d-block d-md-none"></div>
      <div className="banner-search">
        <Container>
          <Row>
            <Col lg={12}>
              <h1>Find your perfect home</h1>
              <p>Or <a href='/property-valuation-in-dubai/'>fill out the form</a>, and we will be in touch within 3 hours</p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <BannerSearch ptypetag="for-sale" homeBanner sales />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default HomeBannerSearch
